import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTransition, animated, config } from '@react-spring/web';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0vw 4vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding-top: 0vw;
    padding: -20vw 9vw;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 25vh;
  }
`;

const Image = styled(animated.img)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  will-change: transform, opacity;
`;

const images = [
  require('./1.png'),
  require('./2.png'),
  require('./3.png'),
  require('./4.png')
];

const Webs = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 3000); // Cambiar cada 3 segundos
    return () => clearInterval(interval);
  }, []);

  const transitions = useTransition(index, {
    keys: index,
    from: { opacity: 0, transform: 'scale(0.8)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.8)' },
    config: config.molasses,
  });

  return (
    <Container>
      <CarouselContainer>
        {transitions((style, i) => (
          <Image key={i} src={images[i]} style={style} />
        ))}
      </CarouselContainer>
    </Container>
  );
};

export default Webs;
